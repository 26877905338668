<template>

    <div class="inplay_content" :class="{'bg_inplay_active':active}" @click="getInplayGame(item)">
        <div class="league_info">
            <div class="pic">
              <img :src="item.league.imageUrl" alt="">
              <div style="margin-left: .2rem">{{item.league.leagueName}}</div>
            </div>
            <div class="it">{{item.startTime|datef('MM월DD일 HH:mm')}}</div>
        </div>
        <div class="bet_live">
            <div class="type_img">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER"
                     src="../../../../assets/images/icon/type/1/football-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL"
                     src="../../../../assets/images/icon/type/1/basketball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL"
                     src="../../../../assets/images/icon/type/1/baseball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL"
                     src="../../../../assets/images/icon/type/1/volleyball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY"
                     src="../../../../assets/images/icon/type/1/hockey-ico.png" alt="">
            </div>
            <div class="more" @click="getInplayGame(item)">+ {{item.gameCount}}</div>
            <div class="team_info" :class="{'inplay_bg_soccer':item.apiTypeId === inplayConst.SPORTS_ID_SOCCER
                        ,'inplay_bg_volleyball':item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL
                        ,'inplay_bg_baseball':item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL
                        ,'inplay_bg_bascketball':item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL
                        ,'inplay_bg_hockey':item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY}">
                <div class="team">
                    <img alt="" :src="inplayConst.TEAM_IMAG + item.homeImgId + '.png'">
                    <span>{{item.homeTeamName}}</span>
                </div>
                <div class="score">
                    <span>{{item.homeScore}}</span>
                </div>
                <div class="timeinfo">
                    <span  style="font-size: 10px">
                         <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER">{{item.timeMm + '\''}}</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{item.timeMm + ':' +
                            item.timeSs}}</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY" style="color: #f5f814">{{item.timeMm + '\''}}</i>
                    </span>
                    <span style="font-size: 10px">
                         <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER && item.halfStatus === '1'">전반</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER && item.halfStatus === '2'">후반</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL">{{item.halfStatus}} N</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{item.halfStatus}}Q</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL">{{item.halfStatus}} S</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY" style="color: #f5f814">{{item.halfStatus}} P</i>
                    </span>
                </div>
                <div class="score">
                    <span>{{item.awayScore}}</span>
                </div>
                <div class="team">
                    <img alt="" :src="inplayConst.TEAM_IMAG + item.awayImgId + '.png'">
                    <span>{{item.awayTeamName}}</span>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import inplayConst from "@/common/inplayConst";
    import sportsConst from "@/common/sportsConst";

    export default {
        name: "InpalyMatch",
        props: {
            item: {
                type: Object,
                default() {
                    return null
                }
            },
            active: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        data(){
            return {
                inplayConst,
                sportsConst,
            }
        },
        methods:{
            getInplayGame(item){
                this.$emit('inplayDetail',item);
            }
        }

    }
</script>

<style scoped>

</style>