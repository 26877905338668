<template>
    <div class="bet_area">
        <div class="w2" @click="selectItem(item,sportsConst.WAY_HOME,item.homeName,item.homeId)"
             :class="{'active_sports':item.selectWay === sportsConst.WAY_HOME}">
            <span class="itn text-left"><slot name="homeName"></slot></span>
            <span class="iho text-center"></span>
            <span class="ibd text-right">{{item.homeOdds}}</span>
            <img alt="up" src="../../../assets/images/icon/oddup.png" class="img-odds-up"
                 :class="{'blinking':item.homeOddsUp}">
            <img alt="down" src="../../../assets/images/icon/odddown.png" class="img-odds-down"
                 :class="{'blinking':item.homeOddsDown}">
            <div class="su-status" v-if="item.homeSu !== inplayConst.SU_ENABLE || item.homeOdds <= 1">
                <i class="fa fa-lock"></i>
            </div>
        </div>
        <div class="w2" @click="selectItem(item,sportsConst.WAY_AWAY,item.awayName,item.awayId)"
             :class="{'active_sports':item.selectWay === sportsConst.WAY_AWAY}">
            <span class="ibd text-left">{{item.awayOdds}}</span>
            <span class="iho text-center"></span>
            <span class="itn2 text-right"><slot name="awayName"></slot></span>
            <img alt="up" src="../../../assets/images/icon/oddup.png" class="img-odds-up "
                 :class="{'blinking':item.awayOddsUp}">
            <img alt="down" src="../../../assets/images/icon/odddown.png" class="img-odds-down"
                 :class="{'blinking':item.awayOddsDown}">
            <div class="su-status" v-if="item.awaySu !== inplayConst.SU_ENABLE || item.awayOdds <= 1">
                <i class="fa fa-lock"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import {sportsFromBetAPI} from "@/common/mixin";

    export default {
        name: "Way2",
        mixins: [sportsFromBetAPI],
    }
</script>

<style scoped>

</style>